import { graphql } from "gatsby";
import React from "react";
import { Seo } from "../Layout/Seo";
import { Blog } from "./Blog";

const BlogPage = ({ pageContext, data }) => {
  const categories = data.allStrapiCategories.nodes;
  const blogs = data.allStrapiBlog.nodes;

  return (
    <Seo
      title={"Blog"}
      description={
        "Todo lo que necesitas saber sobre emigrar y enviar dinero al extranjero está en nuestro blog. ¡Descúbrelo!"
      }
    >
      <Blog data={{ categories, blogs }} pageContext={pageContext} isBlogPage />
    </Seo>
  );
};

export default BlogPage;

export const query = graphql`
  query ($skip: Int!, $limit: Int!, $lang: String!) {
    allStrapiCategories(
      sort: { Title: ASC }
      filter: { locale: { eq: $lang } }
    ) {
      nodes {
        path
        locale
        Title
      }
    }
    allStrapiBlog(
      skip: $skip
      limit: $limit
      sort: { date: DESC }
      filter: { locale: { eq: $lang } }
    ) {
      edges {
        node {
          category {
            Title
            id
            path
            seoDescription
            seoTitle
          }
        }
      }
      nodes {
        id
        date
        seoTitle
        seoDescription
        title
        path
        category {
          id
          Title
        }
        thumbnail {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(
                formats: [AUTO, WEBP]
                quality: 100
                width: 361
                height: 208
              )
            }
            ext
          }
        }
        text
      }
    }
  }
`;
